import { CustomerUnifiedAccountItem, InyovaGrowAccountStatus, StepStatus } from '@inyova/models';

export const toFixed = (num: number, fixed: number | undefined) => {
  const pattern = `^-?\\d+(?:.\\d{0,${fixed || -1}})?`;
  const re = new RegExp(pattern);
  return num.toString().match(re)[0];
};

export function sortByStatus(a: CustomerUnifiedAccountItem, b: CustomerUnifiedAccountItem): number {
  const map = new Map<StepStatus | InyovaGrowAccountStatus, number>();
  map.set(StepStatus.INITIAL, 0);
  map.set(InyovaGrowAccountStatus.INITIAL, 0);
  map.set(StepStatus.ALMOST_PROSPECT, 1);
  map.set(StepStatus.PROSPECT, 2);
  map.set(StepStatus.ALMOST_CUSTOMER, 3);
  map.set(InyovaGrowAccountStatus.OPENED, 3);
  map.set(StepStatus.CUSTOMER, 4);
  map.set(InyovaGrowAccountStatus.FUNDED, 4);

  if (map.get(a.step_status) < map.get(b.step_status)) {
    return -1;
  }
  if (map.get(a.step_status) > map.get(b.step_status)) {
    return 1;
  }
  return 0;
}

export function sortByKind(a: CustomerUnifiedAccountItem, b: CustomerUnifiedAccountItem): number {
  const accountOrder = ['3b', 'interest', '3a', 'kid'];
  return accountOrder.indexOf(a.kind) - accountOrder.indexOf(b.kind);
}

// step_status === 5 or Grow Funded/Opened
export function isCustomer(status: StepStatus | InyovaGrowAccountStatus): boolean {
  switch (status) {
    case StepStatus.CUSTOMER:
    case InyovaGrowAccountStatus.FUNDED:
    case InyovaGrowAccountStatus.OPENED:
      return true;
    default:
      return false;
  }
}

// step_status < 5 or not grow Opened/funded
export function isNotYetCustomer(status: StepStatus | InyovaGrowAccountStatus): boolean {
  return !isCustomer(status);
}

// step_status < 4 or not grow Opened/funded
export function isMaxAlmostCustomer(status: StepStatus | InyovaGrowAccountStatus): boolean {
  switch (status) {
    case StepStatus.CUSTOMER:
    case StepStatus.ALMOST_CUSTOMER:
    case InyovaGrowAccountStatus.FUNDED:
    case InyovaGrowAccountStatus.OPENED:
      return false;
    default:
      return true;
  }
}

// step_status < 3 or not grow Opened/funded (=== 1 or 2)
export function isMaxProspect(status: StepStatus | InyovaGrowAccountStatus): boolean {
  switch (status) {
    case StepStatus.CUSTOMER:
    case StepStatus.ALMOST_CUSTOMER:
    case StepStatus.PROSPECT:
    case InyovaGrowAccountStatus.FUNDED:
    case InyovaGrowAccountStatus.OPENED:
      return false;
    default:
      return true;
  }
}

// step_status > 2
export function isMinProspect(status: StepStatus | InyovaGrowAccountStatus): boolean {
  switch (status) {
    case StepStatus.INITIAL:
    case StepStatus.ALMOST_PROSPECT:
    case InyovaGrowAccountStatus.INITIAL:
      return false;
    default:
      return true;
  }
}

// step_status > 3
export function isMinAlmostCustomer(status: StepStatus | InyovaGrowAccountStatus): boolean {
  switch (status) {
    case StepStatus.INITIAL:
    case StepStatus.ALMOST_PROSPECT:
    case StepStatus.PROSPECT:
    case InyovaGrowAccountStatus.INITIAL:
      return false;
    default:
      return true;
  }
}

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Customer, CustomerAccountItem, CustomerUnifiedAccountItem, StepStatus } from '@inyova/models';
import { isNotYetCustomer, sortByStatus } from '@inyova/utils';
import { TranslateService } from '@ngx-translate/core';

import { select, Store } from '@ngrx/store';
import { noop, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as RootActions from '@app/app.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromRoot from '@app/app.reducers';
import * as fromGrow from '@grow/inyova-grow.reducers';

import { LocalStorageKeys } from '@app/app.constants';
import { InyovaGrowAccount, InyovaGrowAccountStatus } from '@app/shared/models/Grow';
import { State } from '@shared/models/State';
import { AuthService } from '@shared/services/auth.service';
import { TrackingService } from '@shared/services/tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-switch-account',
  templateUrl: './switch-account.component.html',
  styleUrls: ['./switch-account.component.scss']
})
export class SwitchAccountComponent {
  protected readonly onDestroy$ = new Subject<void>();
  @Output() isAccountMenuOpen = new EventEmitter<boolean>();
  selectedAccount: CustomerUnifiedAccountItem;
  customer: Customer;
  kidsAccounts: CustomerAccountItem[];
  accountTypes: string[];
  switchAccountOpened = false;
  account3A: CustomerAccountItem;
  account3B: CustomerAccountItem;
  growAccount: InyovaGrowAccount;
  growAccountStatus = InyovaGrowAccountStatus;
  stepStatus = StepStatus;
  isCIActive = false;

  constructor(
    private store: Store<State>,
    private trackingService: TrackingService,
    private authService: AuthService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.store.pipe(select(fromAccount.selectCustomer), take(1)).subscribe((customer) => {
      if (!customer || !customer.accounts) {
        return;
      }
      this.customer = customer;
      this.kidsAccounts = customer.accounts
        .filter((account) => account.kind === 'kid')
        .sort(sortByStatus)
        .reverse();
      // unique list of account type
      this.accountTypes = [...new Set(customer.accounts.map((account) => account.kind))];

      this.account3A = this.findAccount('3a');
      this.account3B = this.findAccount('3b');
    });
    this.store.pipe(select(fromRoot.selectSelectedAccount), takeUntil(this.onDestroy$)).subscribe((account) => {
      this.selectedAccount = account;
    });

    this.store.pipe(select(fromGrow.selectInyovaGrowAccount), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.growAccount = value;
    });

    this.store.pipe(select(fromRoot.selectCrowdInvestorAccountActive), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.isCIActive = value;
    });

    this.authService.getLastAuthToken().subscribe(noop);
  }

  isActiveOtherAccount(): boolean {
    return this.isCIActive || this.selectedAccount.kind === 'interest';
  }

  findAccount(type: string): CustomerAccountItem {
    return this.customer.accounts.find((account) => account.kind === type);
  }

  createAccount(type: string): void {
    this.trackingService.trackActivity(`[Link] Create a new ${type} account`);
    const url = `${environment.customersApp[this.customer.app_location]}/?customer_token=${localStorage.getItem(
      LocalStorageKeys.AUTHENTICATION_TOKEN
    )}&redirect=product`;
    Browser.open({ url }).finally(noop);
    void Browser.addListener('browserFinished', () => {
      this.router.navigate(['/public/login']).finally(noop);
    });
  }

  switchAccount(targetAccount: CustomerAccountItem): void {
    let account: CustomerUnifiedAccountItem;

    if (targetAccount.kind === '3b' || targetAccount.kind === '3a') {
      account = this.customer.unified_accounts.find((item) => item.kind === targetAccount.kind);
    } else {
      // it's kid account find account by id
      account = this.customer.accounts.find((item) => item.id === (targetAccount as CustomerAccountItem).id);
    }

    // if the account step status < 5 redirect web-app
    if (isNotYetCustomer(account.step_status)) {
      this.trackingService.trackActivity(`[Button] Account switch - Redirect to Web app - ${account.kind}`);
      const url = `${environment.customersApp[this.customer.app_location]}/?customer_token=${localStorage.getItem(
        LocalStorageKeys.AUTHENTICATION_TOKEN
      )}&selected_account=${account.id}`;
      Browser.open({ url }).finally(noop);
      void Browser.addListener('browserFinished', () => {
        this.router.navigate(['/public/login']).finally(noop);
      });
      return;
    }

    // don't trigger if the user is the same
    if (this.selectedAccount?.id !== account.id || this.isCIActive) {
      this.trackingService.trackActivity(`[Button] Account switch - Select ${account.kind} account`);

      this.store.dispatch(
        AccountActions.setSwitchAccountLoading({
          show: true,
          message: this.translateService.instant(`SHARED.accountSwitchSpinner.${account.kind}`, { kidsName: account.owner_name })
        })
      );

      this.store.dispatch(AccountActions.getCurrentAccount({ id: account.id }));
      this.store.dispatch(AccountActions.setCurrentAccountID({ id: account.id }));
      this.store.dispatch(RootActions.setSelectedAccount({ account }));
      //reset others
      this.store.dispatch(RootActions.setCrowdInvestorAccountStatus({ active: false }));
      this.router.navigate(['/tabs/performance']);
    }
    // update menu state
    this.toggleMenu();
  }

  switchAccountToOther(type: 'ci' | 'interest' = 'ci'): void {
    if (this.selectedAccount?.kind === type) {
      return;
    }

    this.trackingService.trackActivity(`[Button] Account switch - Select ${type} account`);

    this.store.dispatch(
      AccountActions.setSwitchAccountLoading({ show: true, message: this.translateService.instant(`SHARED.accountSwitchSpinner.${type}`) })
    );
    if (type === 'ci') {
      this.store.dispatch(RootActions.setCrowdInvestorAccountStatus({ active: true }));
      this.router.navigate(['/tabs/performance']);
    } else {
      this.store.dispatch(RootActions.setCrowdInvestorAccountStatus({ active: false }));
      const account = this.customer.unified_accounts.filter(({ id }) => this.growAccount.id === id)[0];
      this.store.dispatch(RootActions.setSelectedAccount({ account }));

      const growPages = {
        initial: '/grow/risk',
        opened: '/tabs/grow-performance',
        funded: '/tabs/grow-performance'
      };
      const target = growPages[this.growAccount.status];
      this.router.navigate([target]);
    }

    setTimeout(() => {
      this.store.dispatch(AccountActions.setSwitchAccountLoading({ show: false, message: '' }));
    }, 400);

    this.toggleMenu();
  }

  toggleMenu(): void {
    this.trackingService.trackActivity(`[Button] ${this.switchAccountOpened ? 'Close' : 'Open'} Account switch`);
    this.switchAccountOpened = !this.switchAccountOpened;
    this.isAccountMenuOpen.emit(this.switchAccountOpened);
  }

  createKidsAccount(): void {
    this.trackingService.trackActivity('[Link] Y4K - Create a new kids account');
    const url = `${environment.customersApp[this.customer.app_location]}/?customer_token=${localStorage.getItem(
      LocalStorageKeys.AUTHENTICATION_TOKEN
    )}&redirect=kids,register`;
    Browser.open({ url }).finally(noop);
    void Browser.addListener('browserFinished', () => {
      this.router.navigate(['/public/login']).finally(noop);
    });
  }

  isNotYetCustomer(status: StepStatus | InyovaGrowAccountStatus): boolean {
    return isNotYetCustomer(status);
  }
}

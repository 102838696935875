import { CustomerUnifiedAccountItem } from '@inyova/models';

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as AccountActions from '@account/account.actions';
import * as RootActions from '@app/app.actions';

import { BiometricLogin, State } from '@shared/models/State';

export const initialState: RootState = {
  biometricLogin: {
    available: false,
    name: 'biometric',
    type: null
  },
  selectedAccount: null,
  isCrowdInvestorAccountActive: false
};

const rootReducer = createReducer(
  initialState,
  on(RootActions.setBiometricLogin, (state, action) => ({
    ...state,
    biometricLogin: action.payload
  })),
  on(RootActions.setSelectedAccount, (state, action) => ({
    ...state,
    selectedAccount: action.account
  })),
  on(RootActions.setCrowdInvestorAccountStatus, (state, action) => ({
    ...state,
    isCrowdInvestorAccountActive: action.active
  })),
  on(AccountActions.customerLogout, () => ({ ...initialState })),
  on(AccountActions.customerLogoutAndUnpairDevice, () => ({ ...initialState }))
);

export function reducer(state: RootState | undefined, action: Action) {
  return rootReducer(state, action);
}

export interface RootState {
  biometricLogin: BiometricLogin;
  selectedAccount: CustomerUnifiedAccountItem;
  isCrowdInvestorAccountActive: boolean;
}

/*
 * SELECTORS
 */
export const selectFeature = (state: State) => state.root;
// Root reducer selectors
export const selectBiometricLogin = createSelector(selectFeature, (state: RootState) => state.biometricLogin);
export const selectIsGrowAccountActive = createSelector(selectFeature, (state: RootState) => state.selectedAccount?.kind === 'interest');
export const selectSelectedAccount = createSelector(selectFeature, (state: RootState) => state.selectedAccount);
export const selectCrowdInvestorAccountActive = createSelector(selectFeature, (state: RootState) => state?.isCrowdInvestorAccountActive);

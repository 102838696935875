import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { isCustomer } from '@inyova/utils';
import { NavController } from '@ionic/angular';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
class StepStatusGuardService {
  constructor(
    private authService: AuthService,
    private navCtrl: NavController
  ) {}

  canActivate(): boolean {
    if (
      (this.authService.authenticationState.value.step_status && isCustomer(this.authService.authenticationState.value.step_status)) ||
      this.authService.authenticationState.value.isCrowdinvestor ||
      this.authService.authenticationState.value.isGrowAccount
    ) {
      return true;
    } else {
      this.navCtrl.navigateForward('/public/onboarding', { animated: false });
      return false;
    }
  }
}

export const StepStatusGuard: CanActivateFn = () => {
  return inject(StepStatusGuardService).canActivate();
};

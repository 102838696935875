import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import * as RootActions from '@app/app.actions';

import { LocalStorageKeys } from './app.constants';

@Injectable()
export class RootEffects {
  setSelectedAccountLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.setSelectedAccount),
        map(({ account }) => {
          localStorage.setItem(LocalStorageKeys.CURRENT_ACCOUNT_ID, account.id);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
